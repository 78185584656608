import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioMyoaCookies() {

    return (
        <>
            <Helmet>
                <title>Custom Website Design & Development| Myoa Cookies | aBox Agency</title>
                <meta name="title" content="Custom Website Design & Development| Myoa Cookies | aBox Agency" />
                <meta name="description" content="Explore how aBox Agency transformed Myoa Cookies' online store with a custom Figma design and Shopify development. Discover the results of a sleek, user-friendly e-commerce site for premium cookies." />
                <meta name="keywords" content="Gourmet cookie website design, Shopify e-commerce development, custom Figma design, premium cookie store, Myoa Cookies site, e-commerce for gourmet cookies, user-friendly cookie website, online cookie store, cookie gifting options, premium cookie packaging, website design case study, cookie brand online presence, Shopify design solutions, e-commerce site enhancement, gourmet treats website, cookie store redesign, engaging cookie e-commerce site, seamless shopping experience, Myoa Cookies e-commerce, digital cookie branding, website development for cookies, gourmet cookie packaging, elegant cookie site design, high-quality cookie e-commerce, cookie gifting website, premium cookie products online" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/myoa-cookies/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_cookies">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name cookies_text_bg technosport_text">Myoa Cookies</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name cookies_text_bg writing_mod technosport_text">Published in 2024</p>
                                    <h1 className="services_title cookies_text mb-0">
                                        Myoa Cookies <br />
                                        <span className="cookies_title">
                                            Gourmet Cookie E-Commerce Transformation
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content cookies_text mb-3">Myoa Cookies, a brand specializing in handcrafted, gourmet cookies with premium gifting options, needed an e-commerce website that reflected their delectable treats.</p>
                                    <p className="services_content cookies_text">aBox Agency designed a custom Figma interface that captured their brand essence, followed by a user-friendly Shopify development. This ensures a smooth online shopping experience for cookie enthusiasts seeking a delightful indulgence.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://myoacookies.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="cookies_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list myoa_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="cookies_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list myoa_list">
                                                <li>Gourmet Cookies And Snacks</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_cookies_logo">
                                <img src="/assets/image/portfolios/myoa_cookies/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/myoa_cookies/cookies1.webp" className="w-100" alt="Myoa Cookies" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title cookies_title">From Limited Presence to Online Delight</h2>
                                    <p className="services_pera cookies_text font_regular mb-0">
                                        Previously, Myoa Cookies lacked a robust online presence, hindering their ability to reach a wider audience and facilitate online sales. aBox Agency developed a user-friendly Shopify website, allowing customers to effortlessly browse Myoa's exquisite cookie collection and place orders with ease.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies2.webp"
                                        alt="Myoa Cookies" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/myoa_cookies/cookies3.webp"
                                            alt="Myoa Cookies" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/myoa_cookies/cookies4.webp"
                                            alt="Myoa Cookies" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="strenth_bg mt_20 p-0 gaps_sweetly bg_transparent">
                                    <div className="gaps gap_20">
                                        <div className="d-flex justify-content-between flex-column gap_10">
                                            <img src="/assets/image/portfolios/myoa_cookies/cookies5.webp"
                                                alt="Myoa Cookies" className="img-fluid w-100" />
                                            <img src="/assets/image/portfolios/myoa_cookies/cookies6.webp"
                                                alt="Myoa Cookies" className="img-fluid w-100" />
                                        </div>
                                        <div>
                                            <img src="/assets/image/portfolios/myoa_cookies/cookies7.webp"
                                                alt="Myoa Cookies" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies8.webp"
                                        alt="Myoa Cookies" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect cookies_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/myoa_cookies/cookies9.webp"
                                            alt="Myoa Cookies" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/myoa_cookies/cookies10.webp"
                                            alt="Myoa Cookies" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title cookies_title">Revamped Online Presence Achieved</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera cookies_text mb-0">
                                        Myoa Cookies' new e-commerce website <a href="https://myoacookies.com/" target="_blank" className="official_link omsweets_text">https://myoacookies.com/</a> is a visual feast, showcasing their gourmet treats and simplifying online ordering. Intuitive navigation and a secure checkout make buying a breeze, while user-friendly gifting options elevate the experience for every occasion.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies11.webp"
                                        alt="Myoa Cookies" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies12.webp"
                                        alt="Myoa Cookies" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies13.webp"
                                        alt="Myoa Cookies" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies14.webp"
                                        alt="Myoa Cookies" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies15.webp"
                                        alt="Myoa Cookies" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title cookies_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/myoa_cookies/cookies16.webp" className="w-100" alt="myoa cookies color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/myoa_cookies/cookies17.webp" alt="myoa cookies font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="cookies_text perform_pera">aBox revitalized our online presence with a vibrant Shopify website that beautifully showcases our spices and millets. Their blend of traditional and modern e-commerce solutions has significantly enhanced our customer engagement.</p>
                                    <h3 className="title_color padd_s cookies_title">TechnoSport</h3>
                                    <p className="font_medium services_pera cookies_text mb-0 padd_s">Founder of Om Sweets & Snacks</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg sparkfusion_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/spark-fusion" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}