import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioMaaNBaby() {

    return (
        <>
            <Helmet>
                <title>Maa and Baby WorkFolio | Online Parenting Essentials | aBox Agency</title>
                <meta name="title" content="Maa and Baby WorkFolio | Online Parenting Essentials | aBox Agency" />
                <meta name="description" content="Discover the seamless blend of design and functionality in our web showcase, meticulously crafted by aBox Agency. Meta Description: Explore the world of Maa and Baby, your one-stop online destination for baby products and parenting resources." />
                <meta name="keywords" content="Maa and Baby, aBox Agency, Shopify, E-commerce, Web Development, Baby Products, Parenting Resources, Baby Clothing, Baby Accessories, Web Showcase, Client Work, Web Portfolio, Responsive Design, User-Friendly Website, DigitalCraft Studio, Shopify Store, Website Development, Custom Web Design, Online Store Development, Responsive Web Design, User Experience (UX), Web Development Services, eCommerce Solutions, DigitalCraft Studio, Shopify Experts, Website Design, Web Design Trends, Mobile-Friendly Design, Website Maintenance, SEO Optimization, Conversion Rate Optimization, Web Development Portfolio, Online Presence, Baby Toys, Client Success Stories, Creative Showcase, Precious Moments, Nurturing Bonds, Parenthood Essentials, eCommerce Solutions, Web Design, Impactful Design." />
                <link rel="canonical" href="https://www.abox.agency/portfolio/maa-and-baby/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_maababy">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">Maa & Baby</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702962083.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        Elevating Your Parenting <br /> Adventure with a Symphony of Essential Finds
                                        {/* <span className="oxide_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">Maa and Baby, a well-known brand with a strong local following, won praise for the quality of their outstanding baby and toddler products. They chose to enter the online world in an effort to widen their horizons. Understanding their goals and vision, aBox worked with them to support the digital transformation. The end result was the development of a unique Shopify store that satiated all of their needs.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://maaandbaby.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Children and parenting products</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center maababy_logo_bg">
                                <img src="/assets/image/portfolios/maababy/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/maababy/maababy1.webp" className="w-100" alt="maa & baby" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Crafting the Perfect Playlist for Parental Bliss</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our tech virtuosos passionately fine-tuned the Maa & Baby website, ensuring it resonates with the unique needs of parents. This online symphony seamlessly guides you through a diverse array of baby essentials, from whimsical toys to practical portable washing machines, creating a user-friendly experience that hits all the right notes.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/maababy/maababy2.webp"
                                            alt="maa & baby" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/maababy/maababy3.webp"
                                            alt="maa & baby" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/maababy/maababy4.webp"
                                            alt="maa & baby" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/maababy/maababy5.webp"
                                            alt="maa & baby" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/maababy/maababy6.webp"
                                    alt="maa & baby" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_product">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/maababy/maababy7.webp"
                                        alt="maa & baby" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/maababy/maababy8.webp"
                                        alt="maa & baby" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/maababy/maababy9.webp"
                                        alt="maa & baby" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Enhancing the Melody of Parenthood with Thoughtful Details</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        Maa & Baby isn't just about products; it's about composing a joyful parenting melody. Our website, <a href="https://maaandbaby.com/" target="_blank" className="official_link">www.maaandbaby.com</a>, plays the tune of convenience and warmth, inviting parents to explore a harmonious blend of curated finds and helpful resources.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/maababy/maababy10.webp"
                                        alt="maa & baby" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/maababy/maababy11.webp"
                                        alt="maa & baby" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/maababy/maababy12.webp"
                                        alt="maa & baby" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/maababy/maababy13.webp"
                                        alt="maa & baby" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/maababy/maababy14.webp"
                                    alt="maa & baby" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/maababy/maababy15.webp" className="w-100" alt="maa & baby color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/maababy/maababy16.webp" alt="maa & baby font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Abox exceeded our expectations! They crafted our website from start to finish, designed it beautifully, and made everything work seamlessly. The team's service is exceptional—friendly, skilled, and always ready to make our ideas come to life. Highly recommend!</p>
                                    <h3 className="title_color padd_s text_shiska">Shruti</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Maa & Baby</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg gogrub_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/gogrub" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}