import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
// import Lenis from "@studio-freight/lenis";
// import Ukiyo from "ukiyojs";

export default function AboxBranding() {

    // useEffect(() => {
    //     const els = document.querySelectorAll(".ukiyo");
    //     els.forEach((el) => {
    //         new Ukiyo(el);
    //     });

    //     const lenis = new Lenis({
    //         lerp: 0.1,
    //         smooth: true,
    //         direction: 'vertical',
    //     })

    //     function raf() {
    //         lenis.raf()
    //         requestAnimationFrame(raf)
    //     }
    //     requestAnimationFrame(raf)
    // }, []);

    return (
        <>
            <Helmet>
                <title>aBox Agency Brandkit | Showcasing Our Services and Creative Processes | aBox Agency</title>
                <meta name="title" content="aBox Agency Brandkit | Showcasing Our Services and Creative Processes | aBox Agency" />
                <meta name="description" content="At aBox Agency, we are a leading web agency dedicated to creating captivating online experiences. Explore our Showcase Branding page to discover our comprehensive services, innovative processes, and creative solutions for your digital success." />
                <meta name="keywords" content="Shopify, aBox Agency, Web agency, web development, Shopify Plus, Web Design, UIUX, Branding, Visual Identity, AB Testing, Figma, Photoshop, ECommerce, VisualIdentity, Web Design, SEO, Onsite SEO, offsite SEo, A/B Testing, Web Agency Services, Creative Web Design, Digital Branding, Online Marketing Solutions, Showcasing Expertise, Innovative Processes, aBox Agency Showcasing, Creative Web Services, Award-Winning Web Design, Comprehensive Digital Solutions" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/branding/abox/" />
            </Helmet>

            <Header />

            <div className="page_bg default_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name text-white brand_name">ABOX</p>
                                </div>
                                <div className="w-100 position-relative">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="/assets/image/portfolios/abox/abox.mp4" />
                                    </video>
                                    <div className="brand_logo">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="335" height="159" viewBox="0 0 335 159" fill="none">
                                            <path d="M335 158.286H0.757568V0.714355H309.287V26.9763H335V158.286ZM257.866 105.762H283.579V132.024H309.292V105.762H283.579V79.5001H257.866V26.9763H232.154V79.5001H257.866V105.762H232.154V132.024H257.866V105.762ZM129.312 26.9763V132.024H206.446V53.2382H180.733V26.9763H129.312ZM26.4704 26.9763V132.024H103.604V79.5001H77.8912V53.2382H52.1784V26.9763H26.4704ZM309.287 53.2382H283.579V79.5001H309.292L309.287 53.2382ZM180.733 105.762H155.025V53.2382H180.733V105.762ZM77.8912 105.762H52.1784V79.5001H77.8912V105.762Z" fill="#EFEBE7" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name writing_mod text-white brand_name">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">aBox Agency <br /> <span className="text-white">Elevate Your Online Presence with aBox Agency</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-3">aBox Agency is the creative team you need to boost your online image. We excel in creating fantastic digital stores and content that ignites your imagination and captivates your audience.</p>
                                    <p className="services_pera text-white mb-3">Our team of experts specializes in crafting Shopify websites that go beyond the ordinary. We make them incredibly user-friendly, almost like magic. We use beautiful images, user-friendly layouts, and compelling language that piques people's interest.</p>
                                    <p className="services_pera text-white">Each project we Work on is an opportunity to share your brand's unique story in a visually appealing and engaging way. Join us as we venture into the exciting world of the internet, where your online aspirations can truly come to fruition.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <img src="/assets/image/portfolios/abox/abox1.webp" className="banner_logo w-100" alt="aBox Logo" loading="lazy" />
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/abox/abox2.webp" className="w-100" alt="aBox Brand" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Choosing aBox: A Web Marvel</h2>
                                    <p className="services_pera text-white font_regular mb-0">Why aBox? Because we're your one-stop destination for all things web. From crafting impeccable Shopify stores to sleek UI/UX design, web development, creative designing, branding, and more, we're your trusted partner. We bring your digital dreams to life, seamlessly. With aBox, you're not just choosing a service; you're choosing innovation, quality, and excellence.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80 mb-2">
                                <img src="/assets/image/portfolios/abox/abox3.webp" alt="aBox Brand" className="w-100" loading="lazy" />
                            </div>
                            <div className="strenth_bg brand_img p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/abox/abox4.webp"
                                        alt="aBox Brand" className="img-fluid w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/abox/abox5.webp"
                                        alt="aBox Brand" className="img-fluid w-100" loading="lazy" />
                                </div>
                            </div>
                            <div className="my-2">
                                <img src="/assets/image/portfolios/abox/abox6.webp" alt="aBox Brand" className="w-100" loading="lazy" />
                            </div>
                            <div className="strenth_bg brand_img p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/abox/abox7.webp"
                                        alt="aBox Brand" className="img-fluid w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/abox/abox8.webp"
                                        alt="aBox Brand" className="img-fluid w-100" loading="lazy" />
                                </div>
                            </div>
                            <div className="my-2">
                                <img src="/assets/image/portfolios/abox/abox9.webp" alt="aBox Brand" className="w-100" loading="lazy" />
                            </div>
                            <div className="strenth_bg brand_img p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/abox/abox10.webp"
                                        alt="aBox Brand" className="img-fluid w-100" loading="lazy" />
                                    <img src="/assets/image/portfolios/abox/abox11.webp"
                                        alt="aBox Brand" className="img-fluid w-100" loading="lazy" />
                                </div>
                            </div>
                            <div className="my-2">
                                <img src="/assets/image/portfolios/abox/abox12.webp" alt="aBox Brand" className="w-100" loading="lazy" />
                            </div>
                            <div className="pb-5">
                                <img src="/assets/image/portfolios/abox/abox13.webp" alt="aBox Brand" className="w-100" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}