import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioFastNFry() {

    return (
        <>
            <Helmet>
                <title>Fast n Fry Digital Transformation | Frozen Delights & Culinary Web Craft | aBox Agency</title>
                <meta name="title" content="Fast n Fry Digital Transformation | Frozen Delights & Culinary Web Craft | aBox Agency" />
                <meta name="description" content="Discover the digital transformation of Fast n Fry, a testament to aBox Agency’s expertise in web design and development. Explore how we revitalized the brand’s online presence, enhancing the user experience with our innovative approach to showcasing frozen culinary delights." />
                <meta name="keywords" content="Fast n Fry, Frozen Foods, Web Design, aBox Agency, Digital Transformation, User Experience, Online Shopping, Culinary Innovation, Website Development, Brand Revitalization, UI/UX Design, CMS Integration, SEO Strategy, Portfolio Showcase, Client Success, Creative Web Solutions, Technology Integration, E-commerce Excellence, Interactive Design, Project Management, Multimedia Design, Front-End Development, Back-End Development, Brand Identity, Digital Makeover, Taste Meets Technology, Frozen Delights, Cultivated Potatoes, Innovative Interface, Web Agency Portfolio, Success Stories, Digital Marketing, Responsive Web Design" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/fast-n-fry/" />
            </Helmet>

            <Header />

            <div className="page_bg fastfry_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name paper_text_bg text_light">Fast n Fry</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1710325789.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name paper_text_bg text_light writing_mod">Published in 2024</p>
                                    <h1 className="services_title oxide_text mb-0">Fast n Fry <br /> <span>Frozen Bites and Brand’s Digital Makeover</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">ABox proudly presents the virtual feast we cooked up for Fast n Fry! Dive into our latest web design masterpiece, a fusion of style and function that captures the essence of frozen delights and culinary potato magic. Indulge your senses on Fast n Fry's revamped online home, where taste meets technology!</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://fastnfry.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>User Research</li>
                                                <li>Project Management</li>
                                                <li>Brand Design</li>
                                                <li>Interface Design</li>
                                                <li>Interaction Design</li>
                                                <li>Design System</li>
                                                <li>Multimedia Design</li>
                                                <li>Front-End Development</li>
                                                <li>Back-End Development</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Brand Identity</li>
                                                <li>Full Website Development</li>
                                                <li>UIUX</li>
                                                <li>CMS</li>
                                                <li>SEO</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/fastfry/fastfry1.webp" className="w-100" alt="Fast n Fry" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Laying the groundwork for an appetizing brand</h2>
                                    <p className="services_pera text_light font_regular mb-0">Our tech enthusiasts dedicated their expertise to bringing Fast n Fry's vision to fruition, meticulously tailoring each aspect to suit their distinctive requirements. We've fashioned a smooth online shopping journey with an intuitive interface, highlighting our broad assortment of delectable frozen foods and cultivated potatoes.</p>
                                </div>
                            </div>
                            <div className="strenth_my_80 mb-0">
                                <div className="d-flex justify-content-between flex-column gap_10">
                                    <img src="/assets/image/portfolios/fastfry/fastfry2.webp" className="w-100" alt="Fast n Fry" />
                                    <img src="/assets/image/portfolios/fastfry/fastfry3.webp" className="w-100" alt="Fast n Fry" />
                                    {/* <img src="/assets/image/portfolios/paperclip/paperclip4.webp" className="w-100" alt="Fast n Fry" /> */}
                                </div>
                            </div>
                            <div className="aumex strenth_bg pb-0 sweet_delect bg_paperclip">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/fastfry/fastfry4.webp"
                                        alt="Fast n Fry" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/fastfry/fastfry5.webp"
                                        alt="Fast n Fry" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Amplifying the spirit of the brand with flair</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">FastnFry invites you on a tantalizing journey into the world of frozen delights and cultivated potatoes. Our fusion of culinary heritage and cutting-edge technology delivers an enticing experience. Dive into <a href="https://fastnfry.com/" className="text-white portfolio_a" target="_blank">www.fastnfry.com</a> to savor the perfect blend of taste and innovation.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="d-flex justify-content-between flex-column gap_10">
                                    <img src="/assets/image/portfolios/fastfry/fastfry6.webp" className="w-100" alt="Fast n Fry" />
                                    <img src="/assets/image/portfolios/fastfry/fastfry7.webp" className="w-100" alt="Fast n Fry" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Working with aBox for our potato-based frozen foods company, Fast n Fry, was fantastic. Their attentive, creative approach resulted in a visually stunning website that perfectly captures our brand essence. With seamless communication and timely updates, aBox exceeded our expectations, elevating our online presence and attracting positive customer feedback. We highly recommend aBox for its exceptional web design and development services.</p>
                                    <h3 className="text_shiska padd_s">Kushagra</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of fast n Fry</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg neoroots_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/neo-roots" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}