import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioTeaToxLife() {

    return (
        <>
            <Helmet>
                <title>Teatox Life Transformation | Success story of Shopify website| aBox Agency</title>
                <meta name="title" content="Teatox Life Transformation | Success story of Shopify website| aBox Agency" />
                <meta name="description" content="Immerse yourself in the success journey of Teatox Life, a premier wellness brand, through the transformative touch of aBox Agency. Witness the complete website overhaul, product and label designs, 3D renders, and Shopify store makeover that propelled Teatox Life to international acclaim. Explore the natural, high-quality teas and products designed to elevate holistic well-being. Join us in celebrating the fusion of design and functionality that has positioned Teatox Life as a prominent player in the US market." />
                <meta name="keywords" content="Teatox Life, aBox, Shopify, E-commerce, Web Agency, Website Development, Website Redesign, Product Design, Label Design, 3D Renders, Shopify Store Makeover, International Presence, US Market, Wellness Brand, Natural Tea, High-Quality Products, Holistic Well-being, Brand Transformation, Success Stories, Web Design, Creative Solutions, aBox Agency, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization, Health and Wellness, Herbal Teas, Organic Products, Holistic Lifestyle, Wellness Transformation, Design Excellence" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/teatox-life/" />
                <meta property="og:image" content="https://abox.agency/assets/image/og_images/teatox_life.jpg" />
            </Helmet>

            <Header />

            <div className="page_bg bg_teatox">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">TeaTox Life</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702985314.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        WebCraft Wellness: Designing Your Path to <br /> Healthy Living
                                        {/* <span className="oxide_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">Teatox Life, a wellness brand, offers natural, high-quality tea and other products. Their previous website was outdated and lacked essential features. To address this, aBox stepped in, providing a complete website revamp and re-design. Product design, label design for all the products as per emperor brand essence, 3D product renders, and a new design are all featured on the Shopify store makeover. This transformation has enabled Teatox Life to cater to an international audience, with a significant presence in the US market.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://herbalhermit.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>3D Render</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Herbal tea and Wellness Products</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center teatox_banner_bg">
                                <img src="/assets/image/portfolios/teatoxlife/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/teatoxlife/teatox1.webp" className="w-100" alt="teatoxlife" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Creating a digital home for a wellness brand</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our tech gurus meticulously crafted the features of Teatox Life’s website to reflect their unique ethos. We’ve designed an intuitive online shopping platform that showcases their diverse range of herbal wellness products.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/teatoxlife/teatox2.webp"
                                            alt="teatoxlife" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/teatoxlife/teatox3.webp"
                                            alt="teatoxlife" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/teatoxlife/teatox4.webp"
                                            alt="teatoxlife" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/teatoxlife/teatox5.webp"
                                            alt="teatoxlife" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/teatoxlife/teatox6.webp"
                                    alt="teatoxlife" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect teatox_banner_bg">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/teatoxlife/teatox7.webp"
                                        alt="teatoxlife" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/teatoxlife/teatox8.webp"
                                        alt="teatoxlife" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/teatoxlife/teatox9.webp"
                                        alt="teatoxlife" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Capturing the brand’s spirit with sophistication</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        Teatox Life offers a wellness adventure, merging nature’s bounty with contemporary lifestyles in a melody of health benefits. Explore <a href="https://herbalhermit.com/" target="_blank" className="official_link">www.teatoxlife.in</a> for a melody of well-being that will enrich your health journey.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/teatoxlife/teatox10.webp"
                                        alt="teatoxlife" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/teatoxlife/teatox11.webp"
                                        alt="teatoxlife" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/teatoxlife/teatox12.webp"
                                        alt="teatoxlife" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/teatoxlife/teatox13.webp" className="w-100" alt="teatoxlife color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/teatoxlife/teatox14.webp" alt="teatoxlife font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text_light perform_pera">Our collaboration with the tech team has been transformative. The new website truly captures the essence of Teatox Life and our commitment to natural wellness. The team’s dedication to understanding our brand and vision has resulted in a platform that not only enhances our online presence but also provides a seamless experience for our customers. We are now able to reach a wider audience and provide them with our high-quality, sustainable products. This wouldn’t have been possible without the team’s expertise and commitment to our project.</p>
                                    <h3 className="title_color padd_s text_shiska">Mr. Bharat</h3>
                                    <p className="font_medium services_pera text_light mb-0 padd_s">Founder of TeaTox Life</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg aumex_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/aumex" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}