import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioPrakruti() {

    return (
        <>
            <Helmet>
                <title>Prakruti's Wellness Journey | Success Stories: Prakruti | aBox Agency</title>
                <meta name="title" content="Prakruti's Wellness Journey | Success Stories: Prakruti | aBox Agency" />
                <meta name="description" content="Embark on a wellness journey with Prakruti's success story, meticulously crafted by aBox Agency. Discover how our innovative solutions elevated Prakruti's online presence and promoted the highest quality Ayurvedic medicine, health supplements, skin, and hair care products." />
                <meta name="keywords" content="Prakruti Pure Herbs, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Collaborations, Valued Partnerships, Ayurvedic Medicine, Health Supplements, Skin Care, Hair Care, Success Stories, Web Design, Creative Solutions, aBox Agency, Natural Health, Ayurvedic Remedies, Herbal Supplements, Holistic Wellness, Organic Beauty, Nutritional Supplements, Traditional Medicine, Wellness Products, Herbal Healing, Online Portfolio, Web Development, E-commerce Success, Digital Marketing, Responsive Design, SEO Optimization" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/prakruti/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_green_p">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name pr_text2_bg text-white">Prakruti</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1696503065.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name pr_text2_bg writing_mod text-white">Published in 2022</p>
                                    <h1 className="services_title text-white mb-0">Prakruti Pure Herbs <br /> <span className="prakruti_text">Elevating Natural Wellness to New Heights</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Prakruti, renowned for its top-quality ayurvedic health, skin, and hair care products in India, was already thriving in the local and offline markets. However, they recognized the importance of establishing a strong online presence. Seeking assistance, they turned to aBox for guidance throughout the entire process. aBox not only developed their website but also crafted labels, designs, and user interfaces, while also providing support in product design. The comprehensive approach extended to expanding their business internationally. Today, this brand proudly stands as one of India's global ambassadors, serving customers around the world.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://prakrutipureherbs.com/" target="_blank" className="d-flex align-items-center link_btn prakruti_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="prakruti_text services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>3D Render</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="prakruti_text services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Health care supplements</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_prakruti">
                                <img src="/assets/image/portfolios/prakruti/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/prakruti/prakruti2.webp" className="w-100" alt="prakruti" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting the foundation of a holistic brand</h2>
                                    <p className="services_pera text-white font_regular mb-0">Our technical experts worked diligently to ensure that Prakruti Pure Herbs' vision came to life with precision, tailoring each feature to meet their unique requirements. They created a seamless online shopping experience and an intuitive interface, showcasing a diverse range of wellness offerings.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/prakruti/prakruti3.webp"
                                            alt="prakruti" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/prakruti/prakruti4.webp"
                                            alt="prakruti" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/prakruti/prakruti5.webp"
                                            alt="prakruti" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/prakruti/prakruti6.webp"
                                            alt="prakruti" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/prakruti/prakruti7.webp" className="w-100" alt="prakruti" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_prakruti">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/prakruti/prakruti8.webp"
                                        alt="prakruti" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/prakruti/prakruti9.webp"
                                        alt="prakruti" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/prakruti/prakruti10.webp"
                                        alt="prakruti" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text-white">Enhancing the essence of the brand with finesse.</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Prakruti Pure Herbs offers a delightful journey through the world of Ayurveda, seamlessly combining ancient wisdom with modern technology. Visit <a href="https://prakrutipureherbs.com/" className="text-white portfolio_a" target="_blank">prakrutipureherbs.com</a> for a harmonious symphony of well-being and natural beauty.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/prakruti/prakruti11.webp"
                                        alt="prakruti" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/prakruti/prakruti12.webp"
                                        alt="prakruti" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/prakruti/prakruti13.webp"
                                        alt="prakruti" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/prakruti/prakruti14.webp"
                                        alt="prakruti" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/prakruti/prakruti15.webp"
                                    alt="prakruti" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/prakruti/prakruti16.webp" className="w-100" alt="prakruti color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/prakruti/prakruti17.webp" alt="prakruti font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">Working with Box (Niqox) has been a pleasure. They beautifully captured our brand's essence while creating a visually captivating website. Their technical expertise and dedication to our vision resulted in an exceptional online shopping experience for our customers. We highly recommend Box (Niqox) for their professionalism and ability to exceed expectations.</p>
                                    <h3 className="pr_title_color padd_s">Mr. Jayesh</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Owner of Prakruti</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg hyfun_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/hyfun" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}