import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioRepeatGud() {

    return (
        <>
            <Helmet>
                <title>Repeat Gud Portfolio Showcase | Healthy All-Natural Shopify Website | aBox Agency</title>
                <meta name="title" content="Repeat Gud Portfolio Showcase | Healthy All-Natural Shopify Website | aBox Agency" />
                <meta name="description" content="Explore the stunning transformation of Repeat Gud's online presence. Discover how aBox Agency crafted a Shopify site that highlights their commitment to healthy, all-natural condiments and ethical sourcing." />
                <meta name="keywords" content="aBox Agency, niqox, aBox, Shopify experts, Repeat Gud, portfolio showcase, healthy sauces, all-natural condiments, ethical sourcing, Shopify development, web design, e-commerce solutions, Health brand, brand identity, digital transformation, product pages, customer engagement, healthy living, online presence, innovative design, sales growth, web development, site optimization, brand visibility, food tech, flavor-packed sauces, wellness products, sustainable practices, marketing strategies, natural ingredients, sauce recipes, Shopify experts, Shopify solutions, e-commerce optimization, digital marketing, online store design, responsive web design, Shopify themes, conversion rate optimization, customer journey mapping, Shopify integrations, SEO strategies, performance analytics" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/repeat-gud/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_repeat">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Repeat Gud</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1727241048.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title text-white mb-0">
                                        Crafting a Flavorful <br />
                                        <span className="yogathiya_title">
                                            Online Experience
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white mb-3">At aBox, we partnered with Repeat Gud to build a striking Shopify website that embodies their mission of delivering healthy, all-natural condiments. Our strategic approach ensured the site powerfully represents their commitment to ethical sourcing and quality.</p>
                                    <p className="services_content text-white">Through meticulous enhancements of product pages and impactful content creation, we delivered an exceptional user experience. Our dedication to capturing the brand essence resulted in an engaging online platform that showcases their delicious sauces.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://repeatgud.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="yogathiya_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Web Development</li>
                                                <li>UI/UX Improvement</li>
                                                <li>Onsite SEO</li>
                                                <li>Integration</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="yogathiya_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Healthy All-Natural Sauces</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_repeat_logo">
                                <img src="/assets/image/portfolios/repeat_gud/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/repeat_gud/repeat1.webp" className="w-100" alt="Repeat gud" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title yogathiya_title">Revitalizing the Brand's Online Identity</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        We executed transformative improvements across the site, including a compelling other pages and dynamic product displays. Each enhancement was designed to elevate brand identity and significantly boost customer engagement.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat2.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/repeat_gud/repeat3.webp"
                                            alt="Repeat gud" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat4.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat5.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="pb-0 sweet_delect repeat_banner_bg mt_20">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat6.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat7.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat8.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title yogathiya_title">Driving Unprecedented Success with Healthy Conditions</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        Repeat Gud is now witnessing remarkable sales growth and heightened brand recognition, fueled by our expert development and design efforts that transformed their vision for healthy sauces into a thriving reality. Explore the work we did at <a href="https://repeatgud.com/" target="_blank" className="official_link omsweets_text">repeatgud.com</a></p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat9.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat10.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat11.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat12.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/repeat_gud/repeat13.webp"
                                        alt="Repeat gud" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title yogathiya_title">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/repeat_gud/repeat14.webp" className="w-100" alt="Repeat gud color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/repeat_gud/repeat15.webp" alt="Repeat gud font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">Isha here, founder of repeat gud! Let me tell you, Abox Agency is the bomb when it comes to website development. I had this awesome design for my website selling guilt-free, vegan sauces, but the whole coding thing was way over my head. Amazing Team, Handled and delivered the work smoothly, and they are always up for any help. It was great working with them, Abox Agency is your crew!</p>
                                    <h3 className="title_color padd_s yogathiya_title">Repeat Gud</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Repeat Gud</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg nurdle_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/nurdle" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}