import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioAgarwalBhavanSweets() {

    return (
        <>
            <Helmet>
                <title>Agarwal Bhavan Sweets Website | UI/UX, Shopify, Branding | aBox Agency</title>
                <meta name="title" content="Agarwal Bhavan Sweets Website | UI/UX, Shopify, Branding | aBox Agency" />
                <meta name="description" content="Discover how aBox Agency revamped Agarwal Bhavan Sweets’ digital presence with custom UI/UX design, Shopify website development, and branding. Explore our success in boosting their online visibility and customer engagement in the competitive sweets market." />
                <meta name="keywords" content="Agarwal Bhavan Sweets, aBox Agency, digital transformation, UI/UX design, Shopify development, branding, website graphics, Indian sweets online, confectionery e-commerce, custom website design, sweet brand marketing, digital overhaul, e-commerce solutions, branding agency, web development, onsite SEO, confectionery branding, sweet shop website, Indian confectionery, UI/UX agency, Shopify experts, digital branding, enhance online presence, customer engagement, sweet market leader, digital marketing for sweets, branding for confectionery." />
                <link rel="canonical" href="https://www.abox.agency/portfolio/agarwalbhavan-sweets/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_agarwal_sweets">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg omsweets_text">Agarwal Bhavan Sweets</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1728566815.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text-white">Published in 2024</p>
                                    <h1 className="services_title agarwal_sweets_text mb-0">
                                        Digital Makeover <br />
                                        <span className="agarwal_sweets_title">
                                            for Sweet Success
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content agarwal_sweets_text mb-3">Agarwal Bhavan Sweets, a renowned name in traditional Indian confections, sought to enhance its market presence through a digital transformation. With limited online reach, they turned to aBox Agency for a complete digital overhaul.</p>
                                    <p className="services_content agarwal_sweets_text">We delivered custom UI/UX design, Shopify website development, and captivating branding and graphics to showcase their delightful sweets. The result? A standout online identity that captures the essence of their beloved sweets.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.agarwalbhavansweets.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="agarwal_sweets_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list agarwal_sweets_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Branding</li>
                                                <li>UI/UX</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="agarwal_sweets_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list agarwal_sweets_list">
                                                <li>Confectionery sweets</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_agarwal_logo">
                                <img src="/assets/image/portfolios/agarwal_sweets/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets1.webp" className="w-100" alt="Agarwal Sweets" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title agarwal_sweets_title">Custom Solutions to Elevate Brand’s Digital Presence</h2>
                                    <p className="services_pera agarwal_sweets_text font_regular mb-0">
                                        aBox Agency crafted a tailor-made UI/UX, developed a robust Shopify site, and reimagined branding and graphics, providing Agarwal Bhavan Sweets with a vibrant and engaging online platform to boost their digital footprint.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets2.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets3.webp"
                                            alt="Agarwal Sweets" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets4.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets5.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets6.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                </div>
                                <div className="aumex pb-0 sweet_delect bg_agarwal_logo mt_20">
                                    <div className="gaps gap_20">
                                        <div>
                                            <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets7.webp"
                                                alt="Agarwal Sweets" className="img-fluid w-100" />
                                        </div>
                                        <div>
                                            <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets8.webp"
                                                alt="Agarwal Sweets" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title agarwal_sweets_title">Enhanced Online Visibility and Customer Engagement</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera agarwal_sweets_text mb-0">
                                        Our strategic digital enhancements positioned Agarwal Bhavan Sweets as a top contender in the online sweets market, boosting customer engagement, expanding their reach, and driving sales. Visit <a href="https://www.agarwalbhavansweets.com/" target="_blank" className="portfolio_a omsweets_text">www.agarwalbhavansweets.com</a></p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets9.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets10.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets11.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets12.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets13.webp"
                                        alt="Agarwal Sweets" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title agarwal_sweets_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets14.webp" className="w-100" alt="agarwal sweets color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/agarwal_sweets/agarwalsweets15.webp" alt="Agarwal Sweets font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="agarwal_sweets_text perform_pera">We collaborated with aBox Agency for the development and design of our website. Their team was professional, creative, and efficient, delivering a sleek and user-friendly site that perfectly represents our brand. Communication was seamless throughout the project. We highly recommend aBox Agency for exceptional web design and development services.</p>
                                    <h3 className="title_color padd_s agarwal_sweets_title">AuroLab</h3>
                                    <p className="font_medium services_pera agarwal_sweets_text mb-0 padd_s">Founder of AuroLab</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg murari_sweet_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/murari-sweets" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}