import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioBayla() {

    return (
        <>
            <Helmet>
                <title>Client Work Success Story | Bayla Skins | aBox Agency</title>
                <meta name="title" content="Client Work Success Story | Bayla Skins | aBox Agency" />
                <meta name="description" content="Discover the success story of Bayla Skins and how aBox Agency's collaboration led to remarkable results. Explore the journey that transformed Bayla Skins' online presence and brand impact." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Client Work Success Story, Bayla Skins Success, aBox Agency Collaboration, Online Presence Transformation, Client Success Showcase, Brand Impact, Expert Services, Creative Solutions, Brand Enhancement, E-commerce Growth, Client Testimonial, Brand Success Story, E-commerce Branding, Client-Centric Solutions, Business Transformation, Online Store Success, E-commerce Website, Web Design Showcase, Success Through Collaboration, Web Agency Excellence, Branding Success, E-commerce Impact, Web Impact, Box Client Stories, Brand Transformation" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/bayla/" />
                <meta property="og:image" content="https://abox.agency/assets/image/og_images/bayla_skins.jpg" />
            </Helmet>

            <Header />

            <div className="page_bg bayla_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name bayla_text2_bg bayla_text2">bayla skin</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894150.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name bayla_text2_bg bayla_text2 writing_mod">Published in 2022</p>
                                    <h1 className="services_title text-white mb-0"><span>Bayla Skincare</span> <br /> <span className="bayla_title">Embracing Nature's Beauty for Radiant Skin</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Bayla Skins specializes in skincare and beauty products and has fantastic ideas. They aim to expand their reach to the global market by selling their products online. However, they lack knowledge on how to elevate their brand to a premium level and enhance their online presence. And aBox provided them with a comprehensive consultation, guiding them through the entire process. This included developing their website, creating user-friendly interfaces and experiences (UIUX), designing their products and labels, and more to establish a strong and appealing online presence.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://bayla.in/" target="_blank" className="d-flex align-items-center link_btn bayla_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="bayla_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="bayla_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Skincare Products</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bayla_banner bg_banner d-flex justify-content-center align-items-center">
                                <img src="/assets/image/product/bayla/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/bayla/product.webp" className="w-100" alt="Bayla Skincare" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Effortless Shopping Experience</h2>
                                    <p className="services_pera text-white font_regular mb-0">Our technical experts at the web agency worked diligently to provide Bayla Skincare's customers with a smooth and enjoyable shopping experience. Through careful customization and seamless integration with Shopify, we designed an intuitive interface that makes it effortless for visitors to explore the diverse range of organic skincare products.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/bayla/bayla1.webp"
                                            alt="Bayla Skincare" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/bayla/bayla2.webp"
                                            alt="Bayla Skincare" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/bayla/bayla3.webp"
                                            alt="Bayla Skincare" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/bayla/bayla4.webp"
                                            alt="Bayla Skincare" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/product/bayla/bayla_digital.webp" alt="Bayla Skincare" className="w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_white">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/bayla/bayla5.webp"
                                        alt="Bayla Skincare" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bayla/bayla6.webp"
                                        alt="Bayla Skincare" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bayla/bayla7.webp"
                                        alt="Bayla Skincare" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Revealing the Secrets of Beauty</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Bayla Skincare's online presence now mirrors the brand's commitment to transparency and education. We created dedicated sections to educate customers about the advantages of natural skincare, empowering them to make informed decisions on their beauty journey.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/bayla/bayla8.webp"
                                        alt="Bayla Skincare" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bayla/bayla9.webp"
                                        alt="Bayla Skincare" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bayla/bayla10.webp"
                                        alt="Bayla Skincare" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/bayla/bayla11.webp"
                                        alt="Bayla Skincare" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/bayla/bayla12.webp"
                                    alt="Bayla Skincare" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title bayla_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/product/bayla/bayla_platte.webp" className="w-100" alt="bayla color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/product/bayla/untitled_serif.webp" alt="bayla font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">We are thrilled to share our heartfelt testimonial for Box (Niqox). Their exceptional work revamping our online presence has exceeded our expectations. From the outset, their team showed genuine interest in understanding our brand values and vision for Bayla Skincare. The resulting website design, Shopify store, and user experience perfectly showcase the beauty of our natural skincare products. Box (Niqox) truly captured our essence, and we highly recommend their services for any web design and development needs."</p>
                                    <h3 className="bayla_title padd_s">Sai</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Bayla Skincare</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg chhappanbhog_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/chhappanbhog" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}