import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioHyFun() {

    return (
        <>
            <Helmet>
                <title>HyFun Foods Showcase | HyFun's Online Transformation | aBox Agency</title>
                <meta name="title" content="HyFun Foods Showcase | HyFun's Online Transformation | aBox Agency" />
                <meta name="description" content="Explore our web development portfolio showcasing the online transformation of HyFun Foods, curated by WebCraft Studios. Delve into the success story of HyFun's digital journey and the pivotal role our web development solutions played in elevating their online presence." />
                <meta name="keywords" content="HyFun Foods, Frozen Food Products, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Food Branding, WebSculptors Agency, Online Presence Enhancement, Success Stories, Creative Solutions, Frozen Snacks, Food Marketing, Web Development, Portfolio, Success Stories, Online Transformation, WebCraft Studios, Web Development Solutions, Responsive Web Design, E-commerce Integration, User Experience, Enhancement, Mobile-Friendly Websites" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/hyfun/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_green_h">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name pr_text2_bg text-white">Hyfun</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1696502997.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name pr_text2_bg writing_mod text-white">Published in 2022</p>
                                    <h1 className="services_title text-white mb-0">HyFun Foods <br /> <span className="title_color pr_title_color">
                                        Ultimate Destination for
                                        Delicious Veg. Frozen Treats
                                    </span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">HyFun Foods specializes in frozen snacks made with potatoes and has established collaborations with various brands for multiple tasks. Seeking a comprehensive solution, they turned to Abox for a range of services, including designing, web development, web design, product labeling, and other online services. Through Abox's support, this brand has elevated its presence and offerings, now proudly serving renowned brands such as McDonald's, KFC, and others.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.hyfunfoods.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="pr_title_color services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>3D Render</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="pr_title_color services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Potato-Based Frozen Snacks</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_hyfun">
                                <img src="/assets/image/portfolios/hyfun/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/hyfun/hyfun2.webp" className="w-100" alt="hyfun" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting the Cornerstone of a Delicious Brand</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        Our tech experts left no stone unturned to bring HyFun Foods' vision to life. Every feature has been tailored to meet their unique requirements, ensuring a smooth online shopping experience and an intuitive interface that showcases their diverse range of delectable offerings.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/hyfun/hyfun3.webp"
                                            alt="hyfun" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/hyfun/hyfun4.webp"
                                            alt="hyfun" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/hyfun/hyfun5.webp"
                                            alt="hyfun" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/hyfun/hyfun6.webp"
                                            alt="hyfun" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/hyfun/hyfun7.webp" className="w-100" alt="hyfun" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_hyfun">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/hyfun/hyfun8.webp"
                                        alt="hyfun" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hyfun/hyfun9.webp"
                                        alt="hyfun" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hyfun/hyfun10.webp"
                                        alt="hyfun" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text-white">Strengthening the Essence of the Brand with Finesse</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">
                                        HyFun Foods offers a delightful journey that combines the richness of frozen snacks with cutting-edge technology. Experience it for yourself at <a href="https://www.hyfunfoods.com/" className="text-white portfolio_a" target="_blank">www.hyfunfoods.com</a>, where taste and sophistication harmonize in a symphony of flavor.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/hyfun/hyfun11.webp"
                                        alt="hyfun" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hyfun/hyfun12.webp"
                                        alt="hyfun" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hyfun/hyfun13.webp"
                                        alt="hyfun" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hyfun/hyfun14.webp"
                                        alt="hyfun" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/hyfun/hyfun15.webp"
                                    alt="hyfun" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/hyfun/hyfun16.webp" className="w-100" alt="hyfun color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/hyfun/hyfun17.webp" alt="hyfun font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">Working with aBox (Niqox) has been a delight. They beautifully captured our brand's essence while creating a visually captivating website. Their technical expertise and dedication to our vision resulted in an exceptional online shopping experience for our customers. We highly recommend aBox (Niqox) for their professionalism and ability to exceed expectations.</p>
                                    <h3 className="title_color padd_s pr_title_color">Karan Gusain</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">E-commerce Head at Hyfun Food</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg hamdard_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/hamdard" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}