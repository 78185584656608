import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioTechnoSport() {

    return (
        <>
            <Helmet>
                <title>Seamless Shopify Migration | Custom Integrations for TechnoSport | aBox Agency</title>
                <meta name="title" content="Seamless Shopify Migration | Custom Integrations for TechnoSport | aBox Agency" />
                <meta name="description" content="aBox Agency successfully migrated TechnoSport from CartMax to Shopify, ensuring flawless data transfer and enhanced user experience with custom integrations. Explore the new platform and boost your online shopping experience!" />
                <meta name="keywords" content="aBox Agency, Web Agency, Niqox, TechnoSport, Shopify migration, CartMax to Shopify, eCommerce development, custom integrations, online shopping, user experience enhancement, data transfer, sports equipment eCommerce, digital transformation, high-conversion website, aBox Agency, advanced integrations, seamless migration, website optimization, customer satisfaction, sales growth, digital makeover, responsive design, Shopify development, integration solutions, product management, historical data transfer, efficient browsing, customer data security, intuitive interface, sports apparel, retail solutions, brand visibility, innovative eCommerce, digital strategy, user-friendly design, conversion optimization, agile development, performance improvement" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/technosport/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_technosport">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg technosport_text">Techno Sport</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod technosport_text">Published in 2024</p>
                                    <h1 className="services_title technosport_text mb-0">
                                        Techno Sport <br />
                                        <span className="technosport_title">
                                            Flawless Shopify Development & Integration
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content technosport_text mb-3">We executed a flawless Shopify migration, ensuring a pixel-perfect match to TechnoSport’s design. Integrating advanced features, we enhanced the platform for optimal sales and user engagement.</p>
                                    <p className="services_content technosport_text">Data migration from CartMax included the seamless transfer of over 100K orders, products, and customer details, delivering an uninterrupted transition with zero data loss or downtime.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.technosport.in/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="technosport_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list technosport_list">
                                                <li>E-Commerce</li>
                                                <li>Shopify</li>
                                                <li>Branding</li>
                                                <li>Web Development</li>
                                                <li>Onsite SEO</li>
                                                <li>Integration</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="technosport_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list technosport_list">
                                                <li>Active Wear & Sportswear</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_technosport_logo">
                                <img src="/assets/image/portfolios/technosport/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/technosport/technosport1.webp" className="w-100" alt="Technosport" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title technosport_title">Tailored Integrations for Enhanced Performance</h2>
                                    <p className="services_pera technosport_text font_regular mb-0">
                                        We implemented custom solutions for shipping, payments, and user-friendly filters, improving site speed and delivering a seamless, intuitive shopping experience.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/technosport/technosport2.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/technosport/technosport3.webp"
                                            alt="Technosport" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/technosport/technosport4.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/technosport/technosport5.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                </div>
                                <div className="gaps gap_20 gaps_columns mt_20">
                                    <img src="/assets/image/portfolios/technosport/technosport6.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect technosport_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/technosport/technosport7.webp"
                                            alt="Technosport" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/technosport/technosport8.webp"
                                            alt="Technosport" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title technosport_title">Flawless Transition with Elevated Customer Satisfaction</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera technosport_text mb-0">
                                        TechnoSport’s new Shopify site saw increased conversions, greater user engagement, and a more efficient, high-performing e-commerce platform. Visit <a href="https://www.technosport.in/" target="_blank" className="official_link omsweets_text">www.technosport.in</a> to see the transformation.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/technosport/technosport9.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/technosport/technosport10.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/technosport/technosport11.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/technosport/technosport12.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/technosport/technosport13.webp"
                                        alt="Technosport" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title technosport_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/technosport/technosport14.webp" className="w-100" alt="technosport color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/technosport/technosport15.webp" alt="technosport font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="technosport_text perform_pera">aBox revitalized our online presence with a vibrant Shopify website that beautifully showcases our spices and millets. Their blend of traditional and modern e-commerce solutions has significantly enhanced our customer engagement.</p>
                                    <h3 className="title_color padd_s technosport_title">TechnoSport</h3>
                                    <p className="font_medium services_pera technosport_text mb-0 padd_s">Founder of Om Sweets & Snacks</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg westerville_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/westerville" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}