import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioEkkiBekki() {

    return (
        <>
            <Helmet>
                <title>Ekki Bekki WorkFolio | Elevate Spaces with Unique Wallpapers | aBox Agency</title>
                <meta name="title" content="Ekki Bekki WorkFolio | Elevate Spaces with Unique Wallpapers | aBox Agency" />
                <meta name="description" content="Explore the fusion of design and functionality in our meticulously crafted web showcase by aBox Agency. Dive into the world of Ekki Bekki, an online store redefining spaces with a diverse range of wallpapers." />
                <meta name="keywords" content="Ekki Bekki, aBox Agency, Shopify, E-commerce, Web Development, Wallpapers, Home Decor, Interior Design, Online Store, Client Work, Web Portfolio, Responsive Design, User-Friendly Website, DigitalCraft Studio, Shopify Store, Website Development, Custom Web Design, Online Store Development, Responsive Web Design, User Experience (UX), Web Development Services, eCommerce Solutions, DigitalCraft Studio, Shopify Experts, Website Design, Web Design Trends, Mobile-Friendly Design, Website Maintenance, SEO Optimization, Conversion Rate Optimization, Web Development Portfolio, Online Presence, Aesthetic Wallpapers, Living Room Decor, Bedroom Wallpapers, Dining Area Designs, Home Office Wall Decor." />
                <link rel="canonical" href="https://www.abox.agency/portfolio/ekki-bekki/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_ekkibekki">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg text_light">Ekki Bekki</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702978445.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod text_light">Published in 2023</p>
                                    <h1 className="services_title oxide_text mb-0">
                                        Wall Wonders Unleashed <br /> Ekki Bekki's Canvas of Dreams
                                        {/* <span className="oxide_text">
                                            Adventure Haven: <br />
                                            Gear Up for Fun!
                                        </span> */}
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text_light">With an emphasis on realistic and cozy details, Ekki Bekki offers a distinctive approach to wallpaper shopping. The company specializes in wallpaper products and design services. To provide specially created wallpapers suited for particular demands, they turned to aBox's experience. aBox developed and designed their Shopify website, adding all the required features and offering complete solutions. After all, the company finally has the Shopify store they had dreamed of, one that would highlight their creative method of purchasing wallpaper.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://www.ekkibekki.com/" target="_blank" className="d-flex align-items-center link_btn dl_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>3D Render</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>For Startup</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_shiska services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list services_list">
                                                <li>Wall aesthetics and decorations</li>
                                                <li>E-Commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center maababy_logo_bg">
                                <img src="/assets/image/portfolios/ekki_bekki/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/ekki_bekki/ekkibekki1.webp" className="w-100" alt="Ekki Bekki" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text_light">Crafting the Perfect Backdrop for Your Unique Style</h2>
                                    <p className="services_pera text_light font_regular mb-0">
                                        Our design virtuosos and tech maestros joined forces to ensure Ekki Bekki's vision materialized flawlessly, tailoring every aspect to elevate their distinctive offerings. Behold our seamless online shopping experience with an intuitive interface, showcasing the myriad wallpaper wonders ready to adorn your walls.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/ekki_bekki/ekkibekki2.webp"
                                            alt="Ekki Bekki" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/ekki_bekki/ekkibekki3.webp"
                                            alt="Ekki Bekki" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/ekki_bekki/ekkibekki4.webp"
                                            alt="Ekki Bekki" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/ekki_bekki/ekkibekki5.webp"
                                            alt="Ekki Bekki" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/ekki_bekki/ekkibekki6.webp"
                                    alt="Ekki Bekki" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_product">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki7.webp"
                                        alt="Ekki Bekki" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki8.webp"
                                        alt="Ekki Bekki" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki9.webp"
                                        alt="Ekki Bekki" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text_light">Elevating the Essence of Your Space with Finesse</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text_light mb-0">
                                        Ekki Bekki pledges a design odyssey, blending timeless elegance with contemporary trends in a harmonious burst of colors and patterns. Visit <a href="https://www.ekkibekki.com/" target="_blank" className="official_link">www.ekkibekki.com</a> to embark on a journey of aesthetic enhancement that will redefine the character of your living spaces.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki10.webp"
                                        alt="Ekki Bekki" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki11.webp"
                                        alt="Ekki Bekki" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki12.webp"
                                        alt="Ekki Bekki" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki13.webp"
                                        alt="Ekki Bekki" className="img-fluid w-100" />
                                </div>
                            </div>
                            {/* <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/healthoxide/healthoxide15.webp"
                                    alt="Ekki Bekki" className="img-fluid w-100" />
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/ekki_bekki/ekkibekki14.webp" className="w-100" alt="Ekki Bekki color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd mb-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/ekki_bekki/ekkibekki15.webp" alt="Ekki Bekki font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <div className="perform_space">
                                        <p className="text_light perform_pera">We are really impressed with Abox’s website design and development of our artistic brand website. They maintain our brand essence and created a beautiful, user-friendly website that perfectly reflects our artistic style. We highly recommend Abox for their exceptional design, seamless development, and great communication.</p>
                                        <h3 className="title_color padd_s text_shiska">Ekki Bekki</h3>
                                        <p className="font_medium services_pera text_light mb-0 padd_s">Founder of Ekki Bakki</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg mypro_bg">
                    <div className="container">
                        <div className="text-center">
                            <a href="/portfolio/mypro" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}