import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioGiverNutritions() {

    return (
        <>
            <Helmet>
                <title>Client Work Success Story | Fueling Growth: Giver Nutritions | aBox Agency</title>
                <meta name="title" content="Client Work Success Story | Fueling Growth: Giver Nutritions | aBox Agency" />
                <meta name="description" content="Explore the success story of Giver Nutritions, a testament to client work excellence by aBox Agency. Discover how our collaboration fueled growth and elevated Giver Nutrition's online presence." />
                <meta name="keywords" content="Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, Client Work Success Story, Fueling Growth, Giver Nutritions Success, aBox Agency, Client Collaboration, Web Development, Online Presence Enhancement, Client Testimonial, Business Growth, Web Agency Success, Client Success Story, Creative Web Solutions, Web Design Excellence, Empowering Brands, Web Impact, Online Success, Web Development Showcase, Box Client Stories, Giver Nutrition Journey, Client Transformation, Brand Empowerment, Success Through Collaboration, Web Agency Excellence, Empowering Digital Journey, Giver Nutrition" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/giver-nutritions/" />
                <meta property="og:image" content="https://abox.agency/assets/image/og_images/giver_nutritions.jpg" />
            </Helmet>

            <Header />

            <div className="giver_bg page_bg">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name giver_subtext">Giver Nutrition</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1690894255.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name giver_subtext writing_mod">Published in 2023</p>
                                    <h1 className="services_title text-white mb-0">Giver Nutrition <br /> <span className="giver_title">Nourishing Minds, Elevating Performance</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Giver, a brand in the cognitive health industry, initially approached us with a product and website already in place. However, they felt dissatisfied with the current state and sought a complete redesign to align with the essence of their brand. Recognizing their potential for international reach, we provided comprehensive guidance in product design, website redesign and re-development, UIUX design, and various other aspects. Now, Giver's revamped products and services have successfully expanded their reach to more than 80 countries.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://givernutrition.com/" target="_blank" className="d-flex align-items-center link_btn giver_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="giver_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>3D Render</li>
                                                <li>Web Design</li>
                                                <li>Development</li>
                                                <li>For Startup</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="giver_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Essential health nutrition</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner giver_banner d-flex justify-content-center align-items-center">
                                <img src="/assets/image/product/givernutritions/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/product/givernutritions/product.webp" className="w-100" alt="Giver Nutritions" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Crafting a Visual Delight for Nutritional Empowerment</h2>
                                    <p className="services_pera text-white font_regular mb-0">Our team of creative experts carefully designed a visually appealing interface, incorporating vibrant colors, captivating images, and thoughtfully crafted elements that mirror the energy and potential of Giver Nutrition's products. The outcome is an inviting digital space that encourages visitors to begin a transformative journey towards improved mental well-being.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions1.webp"
                                            alt="Giver Nutritions" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions2.webp"
                                            alt="Giver Nutritions" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions3.webp"
                                            alt="Giver Nutritions" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions4.webp"
                                            alt="Giver Nutritions" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/product/givernutritions/giver_digital.webp" alt="Giver Nutritions" className="w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_orange">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions5.webp"
                                        alt="Giver Nutritions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions6.webp"
                                        alt="Giver Nutritions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions7.webp"
                                        alt="Giver Nutritions" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Empowering Minds with Personalized Brand Experiences</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Giver Nutrition's online presence seamlessly combines scientific knowledge with user-friendly design. The website serves as a hub for individuals looking to unlock their cognitive potential, empowering them to make informed decisions and embark on a path towards enhanced mental health.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions8.webp"
                                        alt="Giver Nutritions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions9.webp"
                                        alt="Giver Nutritions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions10.webp"
                                        alt="Giver Nutritions" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions11.webp"
                                        alt="Giver Nutritions" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/giver_nutritions/giver_nutritions12.webp"
                                    alt="Giver Nutritions" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title giver_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/product/givernutritions/giver_palette.webp" className="w-100" alt="Giver Nutritions color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/product/givernutritions/circular.webp" alt="Giver Nutritions font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">Collaborating with aBox (Niqox) has been an extraordinary journey. Their ability to capture the essence of our brand while delivering a visually stunning website is unparalleled. Their unwavering dedication to our vision and their deep technical knowledge resulted in a platform that not only showcases our products but also fosters a sense of trust and credibility. We wholeheartedly recommend aBox (Niqox) for their exceptional professionalism and ability to surpass expectations.”</p>
                                    <h3 className="giver_title padd_s">Vinit Jain</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">Founder of Giver Nutrition</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg bayla_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/bayla" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}