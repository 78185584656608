import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioHamdard() {

    return (
        <>
            <Helmet>
                <title>Hamdard by Unani | India's Leading Unani Brand | aBox Agency</title>
                <meta name="title" content="Hamdard by Unani | India's Leading Unani Brand | aBox Agency" />
                <meta name="description" content="Embark on a healing journey with Hamdard's Ayurvedic medicine portfolio, beautifully presented by Web Solutions India. Discover the success story of Hamdard and our transformative web solutions that enhanced their online presence." />
                <meta name="keywords" content="Hamdard, Hamdard India, Box, Shopify, E-commerce, Web Agency, Web Development, Shopify store, A/B, Our Clients, Client Success Stories, Ayurvedic Medicine, Online Pharmacy, Web Portfolio, Digital Solutions, Unani Medicine, Herbal Remedies, E-commerce, Health and Wellness, Web Development, Portfolio, Success Stories, Online Transformation, WebCraft Studios, Web Development Solutions, Responsive Web Design, E-commerce Integration, User Experience, Enhancement, Mobile-Friendly Websites" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/hamdard/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_hamdard">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name pr_text2_bg text-white">hamdard</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1696502919.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name pr_text2_bg writing_mod text-white">Published in 2023</p>
                                    <h1 className="services_title text-white mb-0">Hamdard
                                        <br />
                                        <span className="title_color text_hamdard">A Digital Oasis for <br />
                                            Wellness Enthusiasts</span></h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content text-white">Hamdard A renowned healthcare product manufacturer and distributor with a quality focus and the legendary Roohafza brand has been a leader in India for over 120 years. Despite a strong reputation across the country, they lacked an online presence. Acknowledging this requirement, aBox was enlisted to develop a website that would highlight the company's rich history without undermining its prestigious reputation. Working in alignment with the brand's vision, aBox provided services such as web design, product label design, and web development and created a user-friendly website that preserves and reflects the brand's longstanding heritage.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://myhamdardstore.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="text_hamdard services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>E-Commerce</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Web Development</li>
                                                <li>3D Render</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="text_hamdard services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list deliverables_list">
                                                <li>Healthcare and pharmaceutical products</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_hamdard_logo">
                                <img src="/assets/image/portfolios/hamdard/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/hamdard/hamdard2.webp" className="w-100" alt="Hamdard" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title text-white">Nurturing the Heart of a Wholesome Brand</h2>
                                    <p className="services_pera text-white font_regular mb-0">
                                        Our technical experts worked tirelessly to make sure Hamdard's vision came true. They customized every feature to meet our unique needs. They created a website that makes shopping online easy and fun, showing off all the great products we have.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 px-0 pt-0 gaps_sweetly bg_transparent">
                                <div className="gaps">
                                    <div>
                                        <img src="/assets/image/portfolios/hamdard/hamdard3.webp"
                                            alt="Hamdard" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_10">
                                        <img src="/assets/image/portfolios/hamdard/hamdard4.webp"
                                            alt="Hamdard" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/hamdard/hamdard5.webp"
                                            alt="Hamdard" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/hamdard/hamdard6.webp"
                                            alt="Hamdard" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <img src="/assets/image/portfolios/hamdard/hamdard7.webp"
                                    alt="Hamdard" className="img-fluid w-100" />
                            </div>
                            <div className="strenth_bg pb-0 sweet_delect bg_hamdard_logo">
                                <div className="gaps">
                                    <img src="/assets/image/portfolios/hamdard/hamdard8.webp"
                                        alt="Hamdard" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hamdard/hamdard9.webp"
                                        alt="Hamdard" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hamdard/hamdard10.webp"
                                        alt="Hamdard" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title text-white">Staying True to Brand Image</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera text-white mb-0">Hamdard's website is a journey that combines tradition with technology. Visit <a href="https://myhamdardstore.com/" className="text-white portfolio_a" target="_blank">myhamdardstore.com</a> for a blend of tradition and modernity that's sure to please.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps hamdard_gaps">
                                    <img src="/assets/image/portfolios/hamdard/hamdard11.webp"
                                        alt="Hamdard" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hamdard/hamdard12.webp"
                                        alt="Hamdard" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/hamdard/hamdard13.webp"
                                        alt="Hamdard" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent">
                                <img src="/assets/image/portfolios/hamdard/hamdard14.webp"
                                    alt="Hamdard" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title text-white">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/hamdard/hamdard15.webp" alt="Hamdard color palette" className="w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/hamdard/hamdard16.webp" alt="Hamdard font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="text-white perform_pera">Working with aBox (Niqox) has been a delight. They beautifully captured our brand's essence while creating a visually captivating website. Their technical expertise and dedication to our vision resulted in an exceptional online shopping experience for our customers. We highly recommend aBox (Niqox) for their professionalism and ability to exceed expectations.</p>
                                    <h3 className="title_color padd_s text_hamdard">Ahmed Hameed</h3>
                                    <p className="font_medium services_pera text-white mb-0 padd_s">CEO of Hamdard</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg thenga_bg_img">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/thenga" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}