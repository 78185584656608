import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioUppercase() {

    return (
        <>
            <Helmet>
                <title>Uppercase’s Website Migration | Eco-friendly Bags & Backpacks | aBox Agency</title>
                <meta name="title" content="Uppercase’s Website Migration | Eco-friendly Bags & Backpacks | aBox Agency" />
                <meta name="description" content="Dive into Uppercase's sustainability journey with a seamless migration to Shopify. Discover their eco-fashion collection and commitment to style and sustainability." />
                <meta name="keywords" content="Uppercase, aBox, Shopify Migration, E-commerce, Sustainable Fashion, Eco-friendly Products, Bags, Backpacks, Web Design, Web Development, SEO Optimization, UI/UX, Visual Identity, Shopify Experts, E-Commerce Solutions, Custom Shopify Development, Shopify Migration Services, Web Design, Development Agency, Shopify Experts, Shopify Plus, Shopify E-commerce, Shopify Store Design, Shopify Website Development, Shopify SEO Optimization, Shopify Theme Customization, Shopify App Development, UI/UX Design, Branding, Marketing, Integration, Shopify Consultation, Shopify Partners, Shopify Experts, Shopify Web Agency, Shopify Development Services, Shopify Customization, Shopify Store Optimization." />
                <link rel="canonical" href="https://www.abox.agency/portfolio/uppercase/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_uppercase">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg uppercase_text">Uppercase</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1724409872.mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod uppercase_text">Published in 2024</p>
                                    <h1 className="services_title uppercase_text mb-0">
                                        Uppercase <br />
                                        <span className="uppercase_title">
                                            Transforming Fashion Sustainability
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content uppercase_text">Uppercase, a trendsetter in eco-friendly bags and backpacks, aimed to revolutionize their online presence. Seeking expert assistance, they partnered with aBox, our esteemed web agency, for a seamless transition from Dukaan to Shopify. We executed a complete migration, ensuring all data seamlessly transferred while crafting a visually captivating UI and enhancing their brand identity.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://uppercase.co.in/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="uppercase_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list uppercase_list">
                                                <li>E-Commerce Migration</li>
                                                <li>Shopify</li>
                                                <li>UI/UX</li>
                                                <li>Web Design</li>
                                                <li>Custom Development</li>
                                                <li>Visual Identity Enhancement</li>
                                                <li>Onsite SEO Optimization</li>
                                                <li>Onsite SEO</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="uppercase_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list uppercase_list">
                                                <li>Eco-Friendly Bags and Backpacks</li>
                                                <li>E-commerce</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_uppercase_logo">
                                <img src="/assets/image/portfolios/uppercase/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/uppercase/uppercase1.webp" className="w-100" alt="Uppercase" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title uppercase_text">Empowering Uppercase’s Green Vision</h2>
                                    <p className="services_pera uppercase_text font_regular mb-0">
                                        aBox dedicated its expertise to actualizing Uppercase’s vision with precision. We crafted a Shopify website tailored to showcase their eco-friendly products seamlessly. Our solutions ensured a smooth online shopping experience, highlighting Uppercase's commitment to sustainability and style.
                                    </p>
                                </div>
                            </div>
                            <div className="strenth_bg p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/uppercase/uppercase2.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/uppercase/uppercase3.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/uppercase/uppercase4.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly mt_20 px-0 pt-0 bg_transparent">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/uppercase/uppercase5.webp"
                                            alt="Uppercase" className="img-fluid w-100" />
                                    </div>
                                    <div className="d-flex justify-content-between flex-column gap_20">
                                        <img src="/assets/image/portfolios/uppercase/uppercase6.webp"
                                            alt="Uppercase" className="img-fluid w-100" />
                                        <img src="/assets/image/portfolios/uppercase/uppercase7.webp"
                                            alt="Uppercase" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="strenth_bg p-0 bg_transparent mt_20">
                                <div className="gaps gaps_columns gap_20">
                                    <img src="/assets/image/portfolios/uppercase/uppercase8.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/uppercase/uppercase9.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title uppercase_text">Bridging Tradition with Innovation</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera uppercase_text mb-0">
                                        Uppercase seamlessly blends heritage with modernity, symbolizing quality and environmental responsibility. Explore <a href="https://uppercase.co.in/" target="_blank" className="official_link uppercase_text">https://uppercase.co.in/</a> to discover their sustainable fashion ethos.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/uppercase/uppercase10.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 mt-0 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/uppercase/uppercase11.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/uppercase/uppercase12.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/uppercase/uppercase13.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/uppercase/uppercase14.webp"
                                        alt="Uppercase" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="div-block-40 flex-column">
                        <div className="strenth_bg strenth_my_80 mt-0 p-0 bg_transparent">
                            <div className="gaps gaps_columns">
                                <img src="/assets/image/portfolios/uppercase/uppercase15.webp"
                                    alt="Uppercase" className="img-fluid w-100" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Color Palette section */}
                <div className="container">
                    <div className="div-block-40 flex-column">
                        <h3 className="palette_title uppercase_text">Color Palette</h3>
                        <div>
                            <img src="/assets/image/portfolios/uppercase/uppercase16.webp" className="w-100" alt="Uppercase color palette" />
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/uppercase/uppercase17.webp" alt="Uppercase font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="uppercase_text perform_pera">We partnered with these guys for our website shift to the Shopify and website redesign. Their team was professional, attentive, and efficient, delivering a seamless transition and a stunning, and great looking site and now it's supporting to the every device you can name. Communication was excellent, and they exceeded our expectations. TBH I recommend aBox to anyone for their brand's web design and development to get the evreything you want for your brand in digital era.</p>
                                    <h3 className="title_color padd_s uppercase_title">Uppercase</h3>
                                    <p className="font_medium services_pera uppercase_text mb-0 padd_s">Founder of Uppercase</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg omsweets_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/om-sweets" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}