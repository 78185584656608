import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Helmet } from "react-helmet";
import SiteLink from "../component/icons/SiteLink";

export default function PortfolioWesterville() {

    return (
        <>
            <Helmet>
                <title>Dental Website Development | WesterVille Dental | aBox Agency</title>
                <meta name="title" content="Dental Website Development | WesterVille Dental | aBox Agency" />
                <meta name="description" content="aBox Craft a patient-centric dental website! We create modern, user-friendly websites with clear info & online booking. Increase patient engagement & brand trust." />
                <meta name="keywords" content="Dental website design, WordPress development, custom Figma designs, dental practice website, user-friendly dental site, aBox Agency, digital dental solutions, professional dental website, website design case study, modern dental website, responsive dental site, dental services website, dental practice online presence, user-centric website design, dental clinic website, high-quality dental web design, dental care website, SEO for dental websites, dental site development, engaging dental web design, dental website redesign, dental site optimization, bespoke dental website, dental web development, dental branding online, aesthetic dental website" />
                <link rel="canonical" href="https://www.abox.agency/portfolio/westerville/" />
            </Helmet>

            <Header />

            <div className="page_bg bg_westerville">
                <div className="header_padding">
                    {/* hero section */}
                    <div className="section_detail">
                        <div className="container-fluid p-0">
                            <div className="d-xl-flex">
                                <div className="product_space">
                                    <p className="product_name dl_text2_bg westerville_text">Westerville</p>
                                </div>
                                <div className="w-100 line_h_0">
                                    {/* <video width={"100%"} autoPlay loop muted playsInline>
                                        <source src="https://d1cihhbb125h48.cloudfront.net/1702980773.mp4" />
                                    </video> */}
                                    <img src="/assets/image/clipchamp.webp" className="w-100" alt="clipchamp" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* detail section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-7 col-lg-8 col-md-10">
                                    <p className="product_name dl_text2_bg writing_mod westerville_text">Published in 2024</p>
                                    <h1 className="services_title westerville_text mb-0">
                                        Westerville <br />
                                        <span className="westerville_title">
                                            A User-Centric Website for Modern Dentistry
                                        </span>
                                    </h1>
                                </div>
                                <div className="vr_border"></div>
                                <div className="col-xl-7 pad_right">
                                    <p className="services_content westerville_text mb-3">WesterVille Dentals, a leading dental practice in Ohio, approached aBox Agency seeking a website that reflected their dedication to patient care and modern dentistry.
                                        A custom Figma design that captured their brand essence, followed by a user-friendly WordPress development. This resulted in a website that offers a seamless browsing experience for patients.
                                    </p>
                                    <p className="services_content westerville_text">In turn, the new website not only showcases WesterVille Dentals' comprehensive dental services but also fosters trust and encourages patients to book appointments directly online.</p>
                                    <ul className="official_web">
                                        <li>
                                            <a href="https://westervilleparkwaydental.com/" target="_blank" className="d-flex align-items-center link_btn hyfun_link">Official Website
                                                <SiteLink />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-5">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className="westerville_title services_menu">Services</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list westerville_list">
                                                <li>Wordpress Site</li>
                                                <li>UI/UX</li>
                                                <li>Visual Identity</li>
                                                <li>Web Design</li>
                                                <li>Development</li>
                                                <li>Onsite SEO</li>
                                                <li>A/B Testing</li>
                                            </ul>
                                        </div>
                                        <div className="menu_list_border"></div>
                                        <div className="col-md-6">
                                            <p className="westerville_title services_menu">Industries</p>
                                        </div>
                                        <div className="col-md-6">
                                            <ul className="services_menu_list westerville_list">
                                                <li>Dentistry & Oral Health</li>
                                                <li>Informative</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logo section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="bg_banner d-flex justify-content-center align-items-center bg_westerville_logo">
                                <img src="/assets/image/portfolios/westerville/logo.svg" className="banner_logo" alt="Logo" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Product section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div>
                                <img src="/assets/image/portfolios/westerville/westerville1.webp" className="w-100" alt="Westerville" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* content Section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row justify-content-center">
                                <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                                    <h2 className="brand_title westerville_title">Engaging Navigation & Clear Information</h2>
                                    <p className="services_pera westerville_text font_regular mb-3">
                                        Building trust with potential patients is key. We incorporated warm visuals, positive patient testimonials, and clear communication to establish a welcoming and reliable online presence. Visit the changes at <a href="https://westervilleparkwaydental.com/" target="_black" className="official_link omsweets_text">westervilleparkwaydental.com</a></p>
                                    <p className="services_pera westerville_text font_regular mb-0">The convenient online booking functionality allows patients to schedule appointments effortlessly, streamlining their dental care journey.</p>
                                </div>
                            </div>
                            <div className="strenth_bg gaps_sweetly p-0 strenth_my_80 mb-0 bg_transparent">
                                <div className="gaps gap_20 gaps_columns">
                                    <img src="/assets/image/portfolios/westerville/westerville2.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                    <div>
                                        <img src="/assets/image/portfolios/westerville/westerville3.webp"
                                            alt="Westerville" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/westerville/westerville4.webp"
                                            alt="Westerville" className="img-fluid w-100" />
                                    </div>
                                </div>
                                <div className="gaps sweet_delect p-0 mt_20 bg-transparent gap_20">
                                    <img src="/assets/image/portfolios/westerville/westerville5.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/westerville/westerville6.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                </div>
                            </div>
                            <div className="aumex pb-0 sweet_delect westerville_banner_bg mt_20">
                                <div className="gaps gap_20">
                                    <div>
                                        <img src="/assets/image/portfolios/westerville/westerville7.webp"
                                            alt="Westerville" className="img-fluid w-100" />
                                    </div>
                                    <div>
                                        <img src="/assets/image/portfolios/westerville/westerville8.webp"
                                            alt="Westerville" className="img-fluid w-100" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Digital brand section */}
                <div className="section_space">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-xxl-6 col-lg-7 col-md-8">
                                    <h2 className="brand_title westerville_title">Enhancing Online Presence for Dental Practice</h2>
                                </div>
                                <div className="col-12">
                                    <p className="services_pera westerville_text mb-0">Cohesive design elements are more than aesthetics. We crafted a website that reflects WesterVille Dentals' brand identity, ensuring consistency across all digital touchpoints. From color palettes to fonts, every pixel embodies their brand personality, fostering recognition and trust.</p>
                                </div>
                            </div>
                            <div className="strenth_bg strenth_my_80 mb-0 p-0 bg_transparent">
                                <div className="gaps gaps_columns">
                                    <img src="/assets/image/portfolios/westerville/westerville9.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section_space pt-0">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="strenth_bg strenth_my_80 p-0 bg_transparent">
                                <div className="gaps sweet_delect p-0 bg-transparent gap_20 mt-0">
                                    <img src="/assets/image/portfolios/westerville/westerville10.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/westerville/westerville11.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/westerville/westerville12.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                    <img src="/assets/image/portfolios/westerville/westerville13.webp"
                                        alt="Westerville" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Palette section */}
                <div>
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <h3 className="palette_title westerville_title">Color Palette</h3>
                            <div>
                                <img src="/assets/image/portfolios/westerville/westerville14.webp" className="w-100" alt="westerville color palette" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Playfair section */}
                <div className="section_padd">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            <div className="row">
                                <div className="col-12">
                                    <img src="/assets/image/portfolios/westerville/westerville15.webp" alt="westerville font" className="w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Testimonial section */}
                <div className="perform_space py-5">
                    <div className="container">
                        <div className="div-block-40 flex-column">
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10">
                                    <p className="westerville_text perform_pera">aBox revitalized our online presence with a vibrant Shopify website that beautifully showcases our spices and millets. Their blend of traditional and modern e-commerce solutions has significantly enhanced our customer engagement.</p>
                                    <h3 className="title_color padd_s westerville_title">TechnoSport</h3>
                                    <p className="font_medium services_pera westerville_text mb-0 padd_s">Founder of Om Sweets & Snacks</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* other project */}
                <div className="next_project_bg cookies_bg">
                    <div className="container">
                        <div className="text-center">
                            {/* <p className="next_button font_regular">Scroll Down to Next</p> */}
                            <a href="/portfolio/myoa-cookies" className="next_button font_regular">Next</a>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}